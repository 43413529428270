























































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BButton, BCol, BRow, BCard, BInputGroup, BCardTitle, BSpinner, BInputGroupAppend, BFormTextarea, BTable, BTab, BTabs,
    BFormCheckboxGroup,
    BFormCheckbox,
    BOverlay
} from "bootstrap-vue";
import {VueGoodTable} from 'vue-good-table'
import {NotifyInfo, NotifySuccess} from "@/helpers/Toast";

@Component({
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BSpinner,
    BInputGroupAppend,
    BTable,
    BTabs,
    BTab,
    BFormCheckboxGroup,
    BFormCheckbox,
    BOverlay
  }
})
export default class GdprRequests extends Vue {
  GdprCheckValue: string = null;

  columns = [
    {
      label: 'Name',
      field: 'fullName',
    },
    {
      label: 'Email',
      field: 'email',
    },
    {
      label: 'Requester',
      field: 'requester',
    },
    {
      label: 'Actions',
      field: 'actions',
      width: "auto"
    }
  ]

  rows: MaintenanceAPI.GDPRRequests.Item[] = [];
  employees: EmployeeAPI.User.User[] = [];
  candidates: CandidateAPI.Candidate.User[] = [];

  async created() {
    await this.getItems();
  }


  async getItems() {
    const rows = await this.$api.maintenance.gdprrequests.GetList();
    this.employees = await this.$api.employee.user.GetByIds(rows.map(x => x.employeeId));

    this.$set(this, "candidates", []);
    await this.getCurrentCandidates(rows);
    await this.getArchiveCandidates(rows);


    for (const rowIndex in rows) {
      rows[rowIndex].candidate = this.candidates.find(x => x.id == rows[rowIndex].userId);
      rows[rowIndex].employee = this.employees.find(x => x.id == rows[rowIndex].employeeId);
    }

    this.rows = rows;
  }

  async getCurrentCandidates(rows) {
    const current_candidates = await this.$api.candidateCurrent.user.GetByIds(rows.filter(x => x.type == "current").map(x => x.userId))
    this.candidates.push(...current_candidates);
  }

  async getArchiveCandidates(rows) {
    const archive_candidates = await this.$api.candidateArchive.user.GetByIds(rows.filter(x => x.type == "archive").map(x => x.userId))
    this.candidates.push(...archive_candidates);
  }

  async actionRequest(type: string, item: MaintenanceAPI.GDPRRequests.Item, bulk = false) {
    if (type == "approve") {
      let success = false;
      if (item.type == "current")
        success = await this.$api.candidateCurrent.compliance.GDPRRemoval(item.userId);

      if (item.type == "archive")
        success = await this.$api.candidateArchive.compliance.GDPRRemoval(item.userId);

      if (success) {
        NotifySuccess([`Successfully Removed All Information Kept on ${item.candidate.firstName} ${item.candidate.lastName} in candidate journey`]);
        await this.$api.maintenance.gdprrequests.Delete(item.id);
      }
    } else {
      await this.$api.maintenance.gdprrequests.Delete(item.id);
    }

    if (!bulk)
      await this.getItems();
  }

  removingCandidates = false;
  async bulkActionRequest() {
    this.$set(this, "removingCandidates", true);

    NotifyInfo([`Processing all requests please wait`]);

    await this.$api.maintenance.gdprrequests.ProcessAll();
    await this.getItems();

    this.$set(this, "removingCandidates", false);

  }


  foundStatus: any = {
    archive: {found: false, removed: false},
    current: {found: false, removed: false, revoked: false, revokedDate: null, employeeId: null},
    digital_registration: {found: false, count: 0}
  };
  checking = false;
  nosearch = true;


  requestProcessing = false
  async SingleGdprCheck() {
    this.$set(this, "requestProcessing", true);
    this.checking = true;

    const item = await this.GdprCheck(this.GdprCheckValue);
    this.foundStatus.archive = {
      removed: item.archive.gdprRemoved,
      found: item.archive.userFound,
      revoked: item.current.gdprRevoked,
      revokedDate: item.current.gdprRevokedDate,
      employeeId: item.current.gdprRevokedEmployee
    };

    this.foundStatus.current = {
      removed: item.current.gdprRemoved,
      found: item.current.userFound,
      revoked: item.current.gdprRevoked,
      revokedDate: item.current.gdprRevokedDate,
      employeeId: item.current.gdprRevokedEmployee
    };

    this.checking = false;
    this.nosearch = false;

    if (item.current.userFound) {
      this.foundStatus.digital_registration = {
        found: item.current.digitalRegistrationCount > 0,
        count: item.current.digitalRegistrationCount ?? 0
      }
    }

    this.$set(this, "requestProcessing", false);
  }

  async singleRemoval() {
    this.$set(this, "requestProcessing", true);

    this.BulkRemovalsValue = this.GdprCheckValue;
    await this.ProcessRemovals()

    this.$set(this, "requestProcessing", false);

  }

  async gdprRevoke(item: any) {
    this.$set(this, "requestProcessing", true);

    NotifyInfo([`Revoking GDPR Request`]);

    if (item.current.removed) {
      const employee = this.$store.getters["user/GET_USER"];

      await this.$api.candidateCurrent.compliance.GDPRRemovalRevoke(this.GdprCheckValue, employee.id);

      NotifySuccess([`Successfully revoked GDPR removal for ${this.GdprCheckValue}`]);

      await this.SingleGdprCheck();
    }

    this.$set(this, "requestProcessing", false);
  }

  async GdprCheck(value: string) {
    return {
      current: await this.$api.candidateCurrent.compliance.GDPRRemovalCheck(value),
      archive: await this.$api.candidateArchive.compliance.GDPRRemovalCheck(value)
    }
  }

  BulkRemovalsValue = null;
  ProcessedRemovals: Array<any> = [];
  ProcessingRemovals = false;

  async ProcessRemovals() {
    this.ProcessedRemovals = [];
    this.ProcessingRemovals = true;

    const emails = this.BulkRemovalsValue.match(/[^\r\n]+/g).map(x => x.toLowerCase().trim());
    let items = await this.$api.maintenance.gdprrequests.BulkCreate(emails);

    for (const item of items) {
      var findItem = this.ProcessedRemovals.find(x => x.value == item.email);
      if (findItem != null) {
        findItem[item.type] = true;
        continue;
      }

      this.ProcessedRemovals.push({
        value: item.email,
        archive: item.type == "archive",
        current: item.type == "current",
      });
    }

    await this.getItems();
    this.ProcessingRemovals = false;
  }

  get BulkRemovalsFoundArchive() {
    return this.ProcessedRemovals.filter(x => x.archive).length;
  }

  get BulkRemovalsFoundCurrent() {
    return this.ProcessedRemovals.filter(x => x.current).length;
  }
}
